body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-loading {
  animation: gradient 3s linear infinite;
}

@keyframes gradient {
	0% {
		background: #ffffff;
	}
  50% {
    background: #f7f7df;
  }
	100% {
		background: #ffffff;
	}
}

.main-container {
  padding-top: 25px;
}

.editor-container {
  margin-right: 25px;
  max-width: 720px;
}

.terminal-container {
  font-size: 16px;
  /* background-color: #212121; */
  background-image: url(https://images.unsplash.com/photo-1498429089284-41f8cf3ffd39?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2000&q=90);
  background-size: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 10px;
}

.terminal-input {
  margin: 10px 0;
}

.terminal-text-input {
  background-color: #7a7a7a;
  border-radius: 5px;
  padding: 12px 14px;
  border: none;
  color: white;
  outline: none;
  width: 100%;
  overflow: hidden;
  resize: none;
}
